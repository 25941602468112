export const OrderStatus = {
    INITIATED:0,
    PENDINGPAYMENT:1,
    PROCESSED:2,
    WAITINGFORCONFIRMATION:3,
    FAILURE:4,
    SUCCESS:5,
    //---
    GetDescription:(id)=>{
        var Descriptions = {
            0:"Initiated",
            1:"Pending Payment",
            2:"Processed",
            3:"Waiting For Confirmation",
            4:"Failure",
            5:"Success",
        }
        return Descriptions[id];
    }
};
export const OrderPaymentStatus = {
    NOTPAID:0,
    PAID:1,
    REFUNDED:2,
    PARTIALREFUND:3,
    PARTIALPAID:4,
    //---
    GetDescription:(id)=>{
        let Descriptions = {
            0:"Not Paid",
            1:"Paid",
            2:"Refunded",
            3:"Partial Refund",
            4:"Partial Paid",
        }
        return Descriptions[id];
    }
};
export const TicketTypes = {
    COMPLAINT:"complaint",
    SUPPORT:"support",
    ENQUIRY:"enquiry",
    GetDescription:(id)=>{
        let Descriptions = {
            complaint:"Complaint",
            support:"Support",
            enquiry:"Enquiry",
        }
        return Descriptions[id];
    }
};
export const ComplaintTypes = {
    transactionsuccessfulaccountnotupdated:"Transaction Successful, Account not updated",
    amountdeductedbilleraccountcreditedbuttransactionidotreceived:"Amount Deducted, biller account credited but transaction ID not received",
    amountdeductedbilleraccountnotcreditedtransactionidnotreceived:"Amount deducted, biller account not credited & transaction ID not received",
    amountdeductedmultipletimes:"Amount Deducted multiple times",
    doublepaymentupdated:"Double payment updated",
    Erroneouslypaidinwrongaccount:"Erroneously paid in wrong account",
};
export const Gender = {
    FEMALE:0,
    MALE:1,
}
export const SupportTicketReplyBy = {
    OWNER:0,
    REPLYER:1,
};
export const PaymentGateways = {
    RAZORPAY:"razorpay",
    GOOGLEPAY:"googlepay",
    GetDescription:(id)=>{
        var Descriptions = {
            razorpay:"Razor Pay",
            googlepay:"Google Pay",
        }
        return Descriptions[id];
    }
};
export const UpiAppPackageName = {
    ANY:'android.intent.action.VIEW',
    GOOGLEPAY:"com.google.android.apps.nbu.paisa.user",
    PHONEPAY:"com.phonepe.app",
};
export const TransactionTypes = {
    WALLETTOPUP:"wallettopup",
    ORDER:"order",
    COMMISSION:"commission",
    BALANCETRANSFER:"balancetransfer",
    BALANCERECEIVED:"balancereceived",
    BALANCEREVERSED:"balancereversed",
    BALANCEREFUNDED:"balancerefunded",
    GetDescription:(id)=>{
        var Descriptions = {
            "wallettopup":'Wallet Topup',
            "order":'Order',
            "commission":'Commission Received',
            "balancetransfer":'Balance Transfer',
            "balancereceived":'Fund Received',
            "balancereversed":'Balance Reversed',
            "balancerefunded":"Received Reversed Balance"
        };
        return Descriptions[id];
    }
};
export const ServiceTypes = {
    WALLETOPUP:"wt",
    MOBILERECHARGE:"mr",
    DTHRECHARGE:"dth",
    POWERBILLPAYMENT:"pb",
    GetDescription:(id)=>{
        var Descriptions = {
            mr:"Mobile Recharge",
            dth:"Dth Recharge",
            pb:"Power Bill Payment",
            wt:"Wallet Topup",
        };
        return Descriptions[id];
    }
};
export const LogTypes = {
    INFO:0,
    WARN:1,
    ERROR:2,
    GetDescription:(id)=>{
        var Descriptions = {
            0:"Infomation",
            1:"Warning",
            2:"Error",
        };
        return Descriptions[id];
    }
};
export const TraceEventTypes = {
    TEST:"test",
    NETWORK:"network",
    GetDescription:(id)=>{
        var Descriptions = {
            "test":"Test Trace",
        };
        return Descriptions[id];
    }
};
//Custom API Response
export const HttpRequestContentTypes = {
    NONE:'none',
    FORMDATA:'form-data',
    JSON:'json',
    XML:'xml',
    Text:'text',
};
export const HttpResponseContentTypes = {
    JSON:'json',
    XML:'xml',
    TEXT:'text',
};