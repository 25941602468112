import React from 'react';
import ReactDOM from 'react-dom/client';

import { configureStore } from '@reduxjs/toolkit'
import { Provider } from 'react-redux';
import portalReducer from "./features/Portal";
import userReducer   from "./features/User";
import modalReducer  from "./features/Modals";
import App from './App';
import reportWebVitals from './reportWebVitals';
import TraceProvider from './opentelemetry';

export const store = configureStore({reducer: {portal : portalReducer, user:userReducer, modal:modalReducer}})
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <TraceProvider>
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  </TraceProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
