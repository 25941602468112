const { registerInstrumentations } = require('@opentelemetry/instrumentation');
const { DocumentLoadInstrumentation } = require('@opentelemetry/instrumentation-document-load');
const { UserInteractionInstrumentation } = require('@opentelemetry/instrumentation-user-interaction');
const { XMLHttpRequestInstrumentation } = require('@opentelemetry/instrumentation-xml-http-request');
const { FetchInstrumentation } = require('@opentelemetry/instrumentation-fetch');
const { getWebAutoInstrumentations } = require('@opentelemetry/auto-instrumentations-web');

const { Resource } = require('@opentelemetry/resources');
const { SemanticResourceAttributes } = require('@opentelemetry/semantic-conventions');

const { WebTracerProvider, BatchSpanProcessor  } = require('@opentelemetry/sdk-trace-web');
const { OTLPTraceExporter }  = require('@opentelemetry/exporter-trace-otlp-http');

const { MeterProvider, PeriodicExportingMetricReader, } = require('@opentelemetry/sdk-metrics');
const { OTLPMetricExporter } = require('@opentelemetry/exporter-metrics-otlp-http');

// Optionally register instrumentation libraries
const resource =  new Resource({[SemanticResourceAttributes.SERVICE_NAME]: process.env.REACT_APP_NAME,[SemanticResourceAttributes.SERVICE_VERSION]: process.env.REACT_APP_VERSION,});
registerInstrumentations({instrumentations: [
    new DocumentLoadInstrumentation(),
    new UserInteractionInstrumentation(),
    getWebAutoInstrumentations({
        '@opentelemetry/instrumentation-fetch': {
          propagateTraceHeaderCorsUrls: /.*/,
          clearTimingResources: true,
          applyCustomAttributesOnSpan(span) {
            span.setAttribute('app.synthetic_request', 'false');
          },
        },
    }),
    new XMLHttpRequestInstrumentation({
        propagateTraceHeaderCorsUrls: ['http://localhost:5001']
    }),
    new FetchInstrumentation(),

]});
//Tracing
const traceProvider = new WebTracerProvider({resource});
const traceExporter = new OTLPTraceExporter({url:`${process.env.REACT_APP_OTEL_TRACE_EXPORT_URL}/v1/traces`,headers: JSON.parse(process.env.REACT_APP_OTEL_TRACE_EXPORT_HEADERS),});
traceProvider.addSpanProcessor(new BatchSpanProcessor(traceExporter));
traceProvider.register();
//Metrics
const meterProvider = new MeterProvider({resource});
const metricExporter = new OTLPMetricExporter({url:`${process.env.REACT_APP_OTEL_TRACE_EXPORT_URL}/v1/metrics`,headers: JSON.parse(process.env.REACT_APP_OTEL_TRACE_EXPORT_HEADERS),});
const metricReader = new PeriodicExportingMetricReader({exporter: metricExporter,exportIntervalMillis: 1000 * 60 * 1});
meterProvider.addMetricReader(metricReader);
//meterProvider.register();

export default function TraceProvider ({ children }) { return (<>{children}</>);}