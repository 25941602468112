import React, { useState, useEffect, useRef } from "react";
import { load as GoogleReCaptchav3} from 'recaptcha-v3'
import { initializeApp } from "firebase/app";
import { getMessaging,isSupported,getToken,onMessage, } from "firebase/messaging";
//--Redux
import {useDispatch} from "react-redux";
import {UpdateBanners, UpdateCompanyDetails} from "./features/Portal";
//--
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {GlobalProvider} from "./context/GlobalContext";
import {CartProvider} from "./context/CartContext";
import {LoginProvider} from "./context/LoginContext";

import { Endpoint, GOOGLE_RECAPTCHAv3_SITE_KEY } from "./globals/defines";
import * as Models from "./globals/models";
import { GetBanners } from "./services/banners";

const localStorage = require('localStorage')
const openTelemetry = require("@opentelemetry/api");

const axios = require('axios').default;
axios.interceptors.request.use(async (config) => {
  	if(config.url.indexOf("googleapis.com") !== -1 || config.url.indexOf("google.com") !== -1  || config.url.indexOf("gstatic") !== -1 || config.url.indexOf("google-analytics.com") != -1) return config;
  	var appcheck		= "";
	var carttoken		= "";
  	var sessiontoken	= "";
  	var refreshtoken	= "";
	//var traceid = await StartTrace(Models.TraceEventTypes.NETWORK,config.url);
	try{
		carttoken    = localStorage.getItem("ct")??"";
		sessiontoken = localStorage.getItem("st")??"";
		refreshtoken = localStorage.getItem("rt")??"";
		//-----------------------
		var values = (typeof config.url)!="string"?[]:config.url.split("/");
		var action = values[values.length-1];
		var AppCheckRequiredFunctions = ["signup","login","resetpassword","verifyotp","savesupportticket",];
		if(AppCheckRequiredFunctions.includes(action)){
			const recaptcha = await GoogleReCaptchav3(GOOGLE_RECAPTCHAv3_SITE_KEY);
			appcheck = await recaptcha.execute(action);
		}
	}
	catch(ex){
		console.log("Google Recharge Execption:",ex);
	}
	//config.headers["traceid"] = traceid;
	config.headers["ct"] = carttoken;
	config.headers["st"] = sessiontoken;
	config.headers["rt"] = refreshtoken;
	config.headers["ac"] = appcheck;
	return config;
});
axios.interceptors.response.use(async (response)=>{
	if(response.headers != undefined){
		var headers = response.config.headers;
		//StopTrace(headers.traceid);
		if(headers['ct'] != undefined && headers['ct'] != null){
			localStorage.setItem("ct",headers['ct']);
	  	}
	  	if(headers['st'] != undefined && headers['st'] != null){
			localStorage.setItem("st",headers['st']);
	  	}
	  	if(headers['userrole'] != undefined && headers['userrole'] != null){
			localStorage.setItem("userrole",headers['userrole']);
	  	}
	}
	return response;
});
function App(props) {
	//--Redux
	const dispatch = useDispatch();
	//--
	const [Skin,setSkin] = useState(localStorage.getItem("Skin")!==null?localStorage.getItem("Skin"):"");
	const [Theme,setTheme] = useState(localStorage.getItem("Theme")!==null?localStorage.getItem("Theme"):"");
	//----
	const [Configs,setConfigs] = useState({datetimeformat:process.env.REACT_APP_datetimeformat,defaultpagesize:process.env.REACT_APP_defaultpagesize,decimalpoints:process.env.REACT_APP_decimalpoints,wallettopupmultiple:process.env.REACT_APP_wallettopupmultiple,ALGOLIA_APP_ID:process.env.REACT_APP_ALGOLIA_APP_ID,ALGOLIA_SEARCH_KEY:process.env.REACT_APP_ALGOLIA_SEARCH_KEY,VAPIDKEY:process.env.REACT_APP_FIREBASE_VAPIDKEY,LiveLoadTimer:process.env.REACT_APP_LiveLoadTimer});
	const [CompanyName,setCompanyName] = useState(localStorage.getItem("CompanyName")!==null?localStorage.getItem("CompanyName"):"");
	const [CompanyCode,setCompanyCode] = useState(localStorage.getItem("CompanyCode")!==null?localStorage.getItem("CompanyCode"):"");
	const [CompanyAddress,setCompanyAddress] = useState(localStorage.getItem("CompanyAddress")!==null?localStorage.getItem("CompanyAddress"):"");
	const [CompanyState,setCompanyState] = useState(localStorage.getItem("CompanyState")!==null?localStorage.getItem("CompanyState"):"");
	const [CompanyCity,setCompanyCity] = useState(localStorage.getItem("CompanyCity")!==null?localStorage.getItem("CompanyCity"):"");
	const [CompanyPincode,setCompanyPincode] = useState(localStorage.getItem("CompanyPincode")!==null?localStorage.getItem("CompanyPincode"):"");
	const [CompanyCountry,setCompanyCountry] = useState(localStorage.getItem("CompanyCountry")!==null?localStorage.getItem("CompanyCountry"):"");
	const [CompanyMobile,setCompanyMobile] = useState(localStorage.getItem("CompanyMobile")!==null?localStorage.getItem("CompanyMobile"):"");
	const [CompanyEmail,setCompanyEmail] = useState(localStorage.getItem("CompanyEmail")!==null?localStorage.getItem("CompanyEmail"):"");
	const [CompanyWebsite,setCompanyWebsite] = useState(localStorage.getItem("CompanyWebsite")!==null?localStorage.getItem("CompanyWebsite"):"");
	const [ReCAPTCHASiteKey,setReCAPTCHASiteKey] = useState("6LchZCocAAAAAO28P9cMlG9EUPRS0wpihEtklXXC");
	const [CompanyWalletTypes,setCompanyWalletTypes] = useState(localStorage.getItem("CompanyWalletTypes")!==null?JSON.parse(localStorage.getItem("CompanyWalletTypes")):[]);
	const [AvailableWalletTypes,setAvailableWalletTypes] = useState(localStorage.getItem("AvailableWalletTypes")!==null?JSON.parse(localStorage.getItem("AvailableWalletTypes")):[]);
	const [CompanyServiceTypes,setCompanyServiceTypes] = useState(localStorage.getItem("CompanyServiceTypes")!==null?JSON.parse(localStorage.getItem("CompanyServiceTypes")):[]);
	const [CompanyShippingMethods,setCompanyShippingMethods] = useState(localStorage.getItem("CompanyShippingMethods")!==null?JSON.parse(localStorage.getItem("CompanyShippingMethods")):[]);
	const [Categories,setCategories] = useState([]);

	const [LoginDetails, setLoginDetails] = useState(localStorage.getItem("LoginDetails")!==null?JSON.parse(localStorage.getItem("LoginDetails")):{LoginStatus:false});
	const [UserRole, setUserRole] = useState(localStorage.getItem("UserRole")!=null && localStorage.getItem("UserRole")!='undefined'?JSON.parse(localStorage.getItem("UserRole")):{name:'unknown',permissions:{}});
	const [UserWalletBalances,setUserWalletBalances] = useState([]);
	const [UserWalletBalancesMap,setUserWalletBalancesMap] = useState({});
	const [CartItems, setCartItems] = useState(localStorage.getItem("CartItems")!=null && localStorage.getItem("CartItems")!='undefined'?JSON.parse(localStorage.getItem("CartItems")):[]);

	
	const firebaseApp = initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG));
	const messaging = getMessaging(firebaseApp);
  const doLogin = async (username,password,rememberme=false)=>{
	try{
	  var postdata = {username,password,rememberme};
	  var res   = await fetch(Endpoint+"login", {method: "POST",mode: "cors",cache: "no-cache",credentials: "same-origin",redirect: "follow",referrerPolicy: "no-referrer",headers: {"Content-Type": "application/json",},body: JSON.stringify(postdata),})
	  var json  = await res.json();
	  console.log("json:",json);
	  if(json.errorcode != "0"){
		return json.errortext;
	  }
	  localStorage.setItem("ct",json.ct);
	  localStorage.setItem("st",json.st);
	  localStorage.setItem("rt",json.rt);
	  var logindetails = {LoginStatus:true,userid:json.userid,displayname:json.displayname,email:json.email,mobile:json.mobile,emailverified:json.emailverified,mobileverified:json.mobileverified,kycverified:json.kycverified,role:json.role,referralid:json.referralid};
	  var userroles = json.userrole;
	  localStorage.setItem("LoginDetails",JSON.stringify(logindetails));
	  localStorage.setItem("UserRole",JSON.stringify(userroles));
	  setLoginDetails(logindetails);
	  setUserRole(userroles);
	  return true;
	}
	catch(ex){
	  console.log("Login Exception:",ex);
	  return "Error.";
	}
  }
  const doLogout = async ()=>{
	try{
	  var res = await fetch(Endpoint+"logout", {method: "GET",mode: "cors",cache: "no-cache",credentials: "same-origin",redirect: "follow",referrerPolicy: "no-referrer"})
	  var json = await res.json();
	  console.log(json);
	  if(json.errorcode != 0){
		return json.errortext;
	  }
	  localStorage.removeItem("ct");
	  localStorage.removeItem("st");
	  localStorage.removeItem("rt");
	  var logindetails = {LoginStatus:false};
	  var userroles = {};
	  localStorage.setItem("LoginDetails",JSON.stringify(logindetails));
	  localStorage.setItem("UserRole",JSON.stringify(userroles));
	  setLoginDetails(logindetails);
	  setUserRole(userroles);
	  return true;
	}catch(error){
	  console.log("DoLogout:",error);
	  return "Error! "+error;
	};
  }
  const doSignup = async (DisplayName,EmailAddress,MobileNumber,Password,ReferralCode)=>{
	try{
	  var ReferredDomain = localStorage.getItem("ReferredDomain")!=undefined?localStorage.getItem("ReferredDomain"):"";
	  var postdata = {DisplayName,EmailAddress,MobileNumber,Password,ReferralCode,ReferredDomain};
	  var res   = await fetch(Endpoint+"signup", {method: "POST",mode: "cors",cache: "no-cache",credentials: "same-origin",redirect: "follow",referrerPolicy: "no-referrer",headers: {"Content-Type": "application/json",},body: JSON.stringify(postdata),})
	  var json  = await res.json();
	  console.log("json:",json);
	  if(json.errorcode != "0"){
		return {status:false,message:json.errortext};
	  }
	  if(json.emailotp == true || json.mobileotp == true){
		return {status:true,message:json.errortext,requestid:json.requestid,emailotp:json.emailotp,mobileotp:json.mobileotp};
	  }
	  localStorage.setItem("ct",json.ct);
	  localStorage.setItem("st",json.st);
	  localStorage.setItem("rt",json.rt);
	  var logindetails = {LoginStatus:true,userid:json.userid,displayname:json.displayname,email:json.email,mobile:json.mobile,emailverified:json.emailverified,mobileverified:json.mobileverified,kycverified:json.kycverified,role:json.role,referralid:json.referralid};
	  var userroles = json.userrole;
	  localStorage.setItem("LoginDetails",JSON.stringify(logindetails));
	  localStorage.setItem("UserRole",JSON.stringify(userroles));
	  setLoginDetails(logindetails);
	  setUserRole(userroles);
	  return {status:true,requestid:json.requestid,emailotp:json.emailotp,mobileotp:json.mobileotp};
	}
	catch(ex){
	  return {status:false,message:ex.toString()};
	}
  }
  async function VerfiySignupOTP(RequestID,EmailOTP,MobileOTP){
	try{
		var postdata = {RequestID,EmailOTP,MobileOTP};
		var res   = await fetch(Endpoint+"verifyotp", {method: "POST",mode: "cors",cache: "no-cache",credentials: "same-origin",redirect: "follow",referrerPolicy: "no-referrer",headers: {"Content-Type": "application/json",},body: JSON.stringify(postdata),})
		var json  = await res.json();
		if(json.errorcode != "0"){
			return {status:false,message:json.errortext};
		}
		localStorage.setItem("ct",json.ct);
		localStorage.setItem("st",json.st);
		localStorage.setItem("rt",json.rt);
		var logindetails = {LoginStatus:true,userid:json.userid,displayname:json.displayname,email:json.email,mobile:json.mobile,emailverified:json.emailverified,mobileverified:json.mobileverified,kycverified:json.kycverified,role:json.role};
		var userroles = json.userrole;
		localStorage.setItem("LoginDetails",JSON.stringify(logindetails));
		localStorage.setItem("UserRole",JSON.stringify(userroles));
		setLoginDetails(logindetails);
		setUserRole(userroles);
		return {status:true,message:"Success"}; 
	}
	catch(ex){
		return {status:false,message:ex.toString()};
	}
  }
  const GetCategories = async()=>{
	fetch(Endpoint+"getcategories", {method: "GET",mode: "cors",cache: "no-cache",credentials: "same-origin",redirect: "follow",referrerPolicy: "no-referrer"})
	  .then((res) => res.json())
	  .then((res) => {
		  if(res.categories != undefined){
			  var cats = [];
			  for(var i=0;i<res.categories.length;i++){
				  var details = res.categories[i];
				  cats.push({
					catid:details.catid,
					catname:details.catname,
					slug:details.slug,
					parencattid:details.parencattid,
					parencatname:details.parencatname,
					childs:details.childs,
					sortorder:details.sortorder,
					imageid:details.imageid,
					status:details.status,
				  });
			  }
			  setCategories(cats);
		  }
	  })
	  .catch((error) => {console.log("Get Categories:",error);});
  }
  const AddToCart = async(Product,Quantity)=>{
	try{
	  var postdata = {Product,Quantity};
	  var res   = await fetch(Endpoint+"addtocart", {method: "POST",mode: "cors",cache: "no-cache",credentials: "same-origin",redirect: "follow",referrerPolicy: "no-referrer",headers: {"Content-Type": "application/json",},body: JSON.stringify(postdata),})
	  var json  = await res.json();
	  if(json.errorcode != "0"){
		return json.errortext;
	  }
	  localStorage.setItem("CartItems",JSON.stringify(json.items));
	  setCartItems(json.items);
	  return true;
	}
	catch(ex){
	  return ex;
	}
  }
  const UpdateCart = async(Product,Quantity,RemoveItem=false)=>{
	try{
	  var postdata = {Product,Quantity,RemoveItem};
	  var res   = await fetch(Endpoint+"updatecart", {method: "POST",mode: "cors",cache: "no-cache",credentials: "same-origin",redirect: "follow",referrerPolicy: "no-referrer",headers: {"Content-Type": "application/json",},body: JSON.stringify(postdata),})
	  var json  = await res.json();
	  if(json.errorcode != "0"){
		return {status:false,message:json.errortext,items:[]};
	  }
	  localStorage.setItem("CartItems",JSON.stringify(json.items));
	  setCartItems(json.items);
	  return {status:true,message:json.errortext,items:json.items,subtotal:json.subtotal,total:json.total};
	}
	catch(ex){
	  return {status:false,items:[],message:ex};
	}
  }
  const GetUserWalletBalances = async()=>{
	try{
		const res   = await fetch(Endpoint+"wallets/balances", {method: "GET",mode: "cors",cache: "no-cache",credentials: "same-origin",redirect: "follow",referrerPolicy: "no-referrer",headers: {"Content-Type": "application/json",},})
		const json  = await res.json();
		if(json.errorcode !== 0){
			return;
		}
		setUserWalletBalances(json.wallets);
		return true;
	}
	catch(ex){
		return "Error.";
	}
  }
  const GetUserPrivilege = (privilege,value="")=>{
	if(UserRole.admintools == true) return true;
	if(UserRole.privileges == undefined) return false;
	if(UserRole.privileges[privilege] == undefined) return false;
	if(value == ""){
	  var keys = Object.keys(UserRole.privileges[privilege]);
	  for(var i=0;i<keys.length;i++){
		if(UserRole.privileges[privilege][keys[i]] == true) return true;
	  }
	}
	else{
	  if(UserRole.privileges[privilege][value] == undefined) return false;
	  return UserRole.privileges[privilege][value]==true?true:false;
	}
	return false;
  }
  useEffect(()=>{
	isSupported().then(async (supported)=>{
	  if(supported == false) return;
	  var token = await getToken(messaging,{vapidKey:process.env.REACT_APP_FIREBASE_VAPIDKEY}).catch((error)=>{console.log("getToken() error:",error);});
	  console.log("Fcm Token : ",token);
	}).catch((error)=>{
	  console.log('isSupported() Error:',error);
	});
	onMessage(messaging,(remoteMessage)=>{console.log("remoteMessage:",remoteMessage);})
	//------------------
	GetBanners().then((result)=>{
		if(result.status) {
			dispatch(UpdateBanners({Banners:result.banners}));
		}
	});
	//------------------
	if(localStorage.getItem("CompanyName") == null || true){
		fetch(Endpoint+"settings", {method: "GET",mode: "cors",cache: "no-cache",credentials: "same-origin",redirect: "follow",referrerPolicy: "no-referrer"})
		.then((res) => res.json())
		.then((res) => {
		  if(res.companyname){
			let servicetypes = {};
			for(const servicetype of res.servicetypes){
				servicetypes[servicetype.code] = servicetype;
			}
			dispatch(UpdateCompanyDetails(
				{
					CompanyName:res.companyname,
					CompanyCode:res.companycode,
					CompanyAddress:res.companyaddress,
					CompanyState:res.companystate,
					CompanyCity:res.companycity,
					CompanyPincode:res.companypincode,
					CompanyCountry:res.companycountry,
					CompanyMobile:res.companymobile,
					CompanyEmail:res.companyemail,
					CompanyWebsite:res.companywebsite,
					CompanyServiceTypes:servicetypes,
					CompanyShippingMethods:res.shippingmethods??[],

					CompanyFacebookLink:res.facebooklink??'',
        			CompanyTwitterLink:res.twitterlink??'',
        			CompanyLinkedinLink:res.linkedinlink??'',
        			CompanyInstagramLink:res.instagramlink??'',
				}
			));

			localStorage.setItem("CompanyName",res.companyname);
			localStorage.setItem("CompanyCode",res.companycode);
			localStorage.setItem("CompanyAddress",res.companyaddress);
			localStorage.setItem("CompanyState",res.companystate);
			localStorage.setItem("CompanyCity",res.companycity);
			localStorage.setItem("CompanyPincode",res.companypincode);
			localStorage.setItem("CompanyCountry",res.companycountry);
			localStorage.setItem("CompanyMobile",res.companymobile);
			localStorage.setItem("CompanyEmail",res.companyemail);
			localStorage.setItem("CompanyWebsite",res.companywebsite);
			localStorage.setItem("CompanyServiceTypes",JSON.stringify(res.servicetypes!=undefined?res.servicetypes:[]));
			localStorage.setItem("CompanyShippingMethods",JSON.stringify(res.shippingmethods!=undefined?res.shippingmethods:[]));

			localStorage.setItem("CompanyFacebookLink",res.facebooklink!=undefined?res.facebooklink:"");
			localStorage.setItem("CompanyTwitterLink",res.twitterlink!=undefined?res.twitterlink:"");
			localStorage.setItem("CompanyLinkedinLink",res.linkedinlink!=undefined?res.linkedinlink:"");
			localStorage.setItem("CompanyInstagramLink",res.instagramlink!=undefined?res.instagramlink:"");

			var companywallettypes = res.wallettypes!=undefined?res.wallettypes:[];
			var availablewallettypes = [];
			for(var i=0;i<companywallettypes.length;i++){
			  if(GetUserPrivilege("wallettypes",companywallettypes[i].code) === true)
				availablewallettypes.push({code:companywallettypes[i].code,name:companywallettypes[i].name});
			}
			localStorage.setItem("CompanyWalletTypes",JSON.stringify(companywallettypes));
			localStorage.setItem("AvailableWalletTypes",JSON.stringify(availablewallettypes));

			localStorage.setItem("skin",res.skin??"Zipto");
			localStorage.setItem("theme",res.theme??"blue");

			setCompanyName(res.companyname);
			setCompanyCode(res.companycode); 
			setCompanyAddress(res.companyaddress);
			setCompanyState(res.companystate);
			setCompanyCity(res.companycity);
			setCompanyPincode(res.companypincode);
			setCompanyCountry(res.companycountry);
			setCompanyMobile(res.companymobile); 
			setCompanyEmail(res.companyemail); 
			setCompanyWebsite(res.companywebsite); 
			setCompanyWalletTypes(companywallettypes); 
			setAvailableWalletTypes(availablewallettypes); 
			setCompanyServiceTypes(res.servicetypes!=undefined?res.servicetypes:[]); 
			setCompanyShippingMethods(res.shippingmethods!=undefined?res.shippingmethods:[]);

			setSkin(res.skin??"Zipto");
			setTheme(res.theme??"blue");
		  }
		})
		.catch((error) => {console.log("Company Profile:",error);});
	}
	if(localStorage.getItem("ct") == null){
	  fetch(Endpoint+"getsession", {method: "GET",mode: "cors",cache: "no-cache",credentials: "same-origin",redirect: "follow",referrerPolicy: "no-referrer"})
	  .then((res) => res.json())
	  .then((res) => {
		if(res.errorcode != 0) return;
		localStorage.setItem("ct",res.ct);
	  })
	  .catch((error) => {console.log("Company Profile:",error);});
	}
	//----------------
	if(LoginDetails.LoginStatus === true) GetUserWalletBalances();
	if(document.referrer){
	  localStorage.setItem("ReferredDomain",document.referrer);
	}
	GetCategories();
  },[]);
  	if(Skin === "" || Theme === ""){
		return (
		<>
		<div style={{display:"flex",position:"absolute",top:0,left:0,right:0,bottom:0,flex:1,justifyContent:"center",alignItems:"center",}}>
			<h1 style={{color:"black",opacity:0.7}}>Please Wait...</h1>
			<div className="loader"></div>

		</div>
		</>
		);
	}
	const Root = require('./skins/'+Skin+'/navigator/Root').default;
    return (
	<GlobalProvider value={{
        messaging,performance,
        Endpoint, Configs, ReCAPTCHASiteKey, 
        CompanyName, CompanyCode, CompanyAddress, CompanyCity, CompanyPincode, CompanyState, CompanyCountry, CompanyMobile, CompanyEmail, CompanyWebsite, CompanyWalletTypes,AvailableWalletTypes, CompanyServiceTypes,CompanyShippingMethods,
        Categories, AddToCart, UpdateCart,
        doLogin, doLogout, doSignup, VerfiySignupOTP, 
		}}>
      <CartProvider value={{CartItems,setCartItems,}}>
      <LoginProvider value={{LoginDetails,setLoginDetails,UserRole,
			UserWalletBalances,setUserWalletBalances,
			UserWalletBalancesMap,setUserWalletBalancesMap,
			GetUserPrivilege}}>
        <HelmetProvider>
          	<Root skin={Skin} theme={Theme} />
        </HelmetProvider>
      </LoginProvider>
      </CartProvider>
	</GlobalProvider>
  );
}

export default App;
