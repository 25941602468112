import React, { Suspense, useState, useEffect, useRef } from "react";
import { BrowserRouter,Routes, Route,} from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { UpdateBannerTypes } from '../../../features/Portal';
import "../assets/css/themify-icons.css";
import "../assets/css/style.css";

import FallbackScreen from "../Screens/FallbackScreen";
import { Endpoint } from "../../../globals/defines";

const PageNotFoundScreen  = React.lazy(() => import("../Screens/PageNotFoundScreen"));
const MobileRechargeScreen = React.lazy(() => import("../Screens/MobileRechargeScreen"));
const DthRechargeScreen = React.lazy(() => import("../Screens/DthRechargeScreen"));
const PowerBillPaymentScreen = React.lazy(() => import("../Screens/PowerBillPaymentScreen"));
const PostpaidBillPaymentScreen = React.lazy(() => import("../Screens/PostpaidBillPaymentScreen"));
const PaymentScreen = React.lazy(() => import("../Screens/PaymentScreen"));
const ProcessPaymentScreen = React.lazy(() => import("../Screens/ProcessPaymentScreen"));
const OrderScreen = React.lazy(() => import("../Screens/OrderScreen"));
const ControlPanelScreen = React.lazy(() => import("../Screens/ControlPanelScreen"));
const DownlineUsersScreen = React.lazy(() => import("../Screens/DownlineUsersScreen"));
const TransactionsScreen = React.lazy(() => import("../Screens/TransactionsScreen"));
const CommissionSlabsScreen = React.lazy(() => import("../Screens/CommissionSlabsScreen"));
const OperatorsScreen = React.lazy(() => import("../Screens/OperatorsScreen"));
const UserRolesScreen = React.lazy(() => import("../Screens/UserRolesScreen"));
const WhitelabelsScreen = React.lazy(() => import("../Screens/WhitelabelsScreen"));
const WalletTypesScreen = React.lazy(() => import("../Screens/WalletTypesScreen"));
const ServiceRoutesScreen = React.lazy(() => import("../Screens/ServiceRoutesScreen"));
const ServiceProvidersScreen = React.lazy(() => import("../Screens/ServiceProvidersScreen"));
const PaymentGatewaysScreen = React.lazy(() => import("../Screens/PaymentGatewaysScreen"));
//const PaymentGatewayTransactionsScreen = React.lazy(() => import("../Screens/PaymentGatewayTransactionsScreen"));
const PaymentGatewayRouteScreen = React.lazy(() => import("../Screens/PaymentGatewayRouteScreen"));
const SMSGatewaysScreen = React.lazy(() => import("../Screens/SMSGatewaysScreen"));
const ChangePasswordScreen  = React.lazy(() => import("../Screens/ChangePasswordScreen"));
const ProfileScreen  = React.lazy(() => import("../Screens/ProfileScreen"));
const BannersScreen  = React.lazy(() => import("../Screens/BannersScreen"));
const SettingsScreen  = React.lazy(() => import("../Screens/SettingsScreen"));
const ContactScreen = React.lazy(() => import("../Screens/ContactScreen"));
const SupportTicketsScreen = React.lazy(() => import("../Screens/SupportTicketsScreen"));
const CompanyPageScreen = React.lazy(() => import("../Screens/CompanyPageScreen"));

function Root({theme}) {
  const themes = {"red":"color-theme-red","green":"color-theme-green","blue":"color-theme-blue","deepblue":"color-theme-deepblue","yellow":"color-theme-yellow","orange":"color-theme-orange","purple":"color-theme-purple","deeppurple":"color-theme-deeppurple","lightblue":"color-theme-lightblue","teal":"color-theme-teal","lime":"color-theme-lime","deeporange":"color-theme-deeporange","gray":"color-theme-gray","white":"color-theme-white","black":"color-theme-black","brown":"color-theme-brown","darkgreen":"color-theme-darkgreen","deeppink":"color-theme-deeppink","darkorchid":"color-theme-darkorchid"};
  const BANNER_TYPES=[{code:"home",name:"Home Page Side Banner",description:"Banner On Home Page Right Side.",width:270,height:570}];
  const dispatch = useDispatch();
  const CompanyName = useSelector((state)=>{return state.portal.CompanyName;})
  const LoginStatus = useSelector((state)=>{return state.user.LoginStatus;})
  useEffect(()=>{
	dispatch(UpdateBannerTypes({BannerTypes:BANNER_TYPES}));
  },[]);
  return (
	<>
	<Helmet>
		<title>{CompanyName}</title>
		<link rel="icon" type="image/png" sizes="16x16" href={Endpoint+"images/icon"} />
	</Helmet>
	<div className={themes[theme]+" open-font"}>
		<Suspense fallback={<FallbackScreen />}>
			<BrowserRouter>
				<Routes>
				<Route path="/" element={<MobileRechargeScreen />} />
				<Route path="/mobile-recharge" element={<MobileRechargeScreen />} />
				<Route path="/dth-recharge" element={<DthRechargeScreen />} />
				<Route path="/electricity-bill-payment" element={<PowerBillPaymentScreen />} />
				<Route path="/postpaid-bill-payment" element={<PostpaidBillPaymentScreen />} />
				<Route path="/contact" element={<ContactScreen />} />
				{
				LoginStatus === true?
				<>
					<Route path="/payment/:OID" element={<PaymentScreen />} />
					<Route path="/processpayment/:OID" element={<ProcessPaymentScreen />} />
					<Route path="/order/:OID" element={<OrderScreen />} />
					<Route path="/controlpanel" element={<ControlPanelScreen />} />
					<Route path="/downline" element={<DownlineUsersScreen />} />
					<Route path="/transactions" element={<TransactionsScreen />} />
					<Route path="/commissionslabs" element={<CommissionSlabsScreen />} />
					<Route path="/operators" element={<OperatorsScreen />} />
					<Route path="/userroles" element={<UserRolesScreen />} />
					<Route path="/whitelabels" element={<WhitelabelsScreen />} />
					<Route path="/wallettypes" element={<WalletTypesScreen />} />
					<Route path="/serviceroutes" element={<ServiceRoutesScreen />} />
					<Route path="/serviceproviders" element={<ServiceProvidersScreen />} />
					<Route path="/paymentgateways" element={<PaymentGatewaysScreen />} />
					{/*<Route path="/seller/paymentgatewaytransactions" element={<PaymentGatewayTransactionsScreen />} />*/}
					<Route path="/paymentgatewayroute" element={<PaymentGatewayRouteScreen />} />
					<Route path="/smsgateways" element={<SMSGatewaysScreen />} />
					<Route path="/banners" element={<BannersScreen />} />
					<Route path="/password" element={<ChangePasswordScreen />} />
					<Route path="/profile" element={<ProfileScreen />} />
					<Route path="/settings" element={<SettingsScreen />} />
					<Route path="/supporttickets" element={<SupportTicketsScreen />} />
				</>
				:
				<>
					
				</>
				}
				<Route path="/home" element={<MobileRechargeScreen />} />
				<Route path="/aboutus" element={<CompanyPageScreen pagecode="aboutus" />} />
				<Route path="/terms" element={<CompanyPageScreen pagecode="termsandconditions" />} />
				<Route path="/privacy-policy" element={<CompanyPageScreen pagecode="privacypolicy" />} />
				<Route path="/refund-policy" element={<CompanyPageScreen pagecode="refundpolicy" />} />
				<Route path="/shipping-policy" element={<CompanyPageScreen pagecode="shippingpolicy" />} />
				<Route path="*" element={<PageNotFoundScreen />} />
				</Routes>
			</BrowserRouter>
		</Suspense>
	</div>
	</>
  );
} 

export default Root; 