export const Endpoint                       = process.env.REACT_APP_Endpoint;//(window.location.host.split(":")[0]=="localhost"||window.location.host.split(":")[0]=="127.0.0.1"||window.location.host.split(":")[0]=="172.20.10.7")?"http://"+window.location.host.split(":")[0]+":5000/multi-recharge-software/asia-south1/":"/api/";
export const Defines_OrderPaymentStatus    = {"0":"Not Paid","1":"Paid","2":"Refunded","3":"Partial Refund","4":"Partial Paid"};
export const Defines_OrderStatus           = {"0":"Initiated","1":"Pending Payment","2":"Processed","3":"Waiting For Confirmation","4":"Failure","5":"Success"};
export const Defines_TransactionTypes      = {"wallettopup":'Wallet Topup',"order":'Order',"commission":'Commission Received',"balancetransfer":'Balance Transfer',"balancereceived":'Fund Received',"balancereversed":'Balance Reversed',"balancerefunded":"Received Reversed Balance"};
export const GOOGLE_RECAPTCHAv3_SITE_KEY   = "6LeM7UYgAAAAACKsywO6SyNeFm4lC31RCCfQAWXA";
export const GOOGLE_RECAPTCHAv2_SITE_KEY   = "6LeX7UYgAAAAAOTlpOUesiZNdtJg0axVup5xux3o";
export const Defines_SupportTicketTypes    = [{code:"complaint",name:"Complaint"},{code:"support",name:"Support"},{code:"enquiry",name:"Enquiry"}];
export const Defines_SupportTicketTypesMap = {complaint:"Complaint",support:"Support",enquiry:"Enquiry,"};
export const Defines_ComplaintTypes = [
    {code:"transactionsuccessfulaccountnotupdated",name:"Transaction Successful, Account not updated"},
    {code:"amountdeductedbilleraccountcreditedbuttransactionidotreceived",name:"Amount Deducted, biller account credited but transaction ID not received"},
    {code:"amountdeductedbilleraccountnotcreditedtransactionidnotreceived",name:"Amount deducted, biller account not credited & transaction ID not received"},
    {code:"amountdeductedmultipletimes",name:"Amount Deducted multiple times"},
    {code:"doublepaymentupdated",name:"Double payment updated"},
    {code:"Erroneouslypaidinwrongaccount",name:"Erroneously paid in wrong account"},
];
export const Defines_ComplaintTypesMap = {
    transactionsuccessfulaccountnotupdated:"Transaction Successful, Account not updated",
    amountdeductedbilleraccountcreditedbuttransactionidotreceived:"Amount Deducted, biller account credited but transaction ID not received",
    amountdeductedbilleraccountnotcreditedtransactionidnotreceived:"Amount deducted, biller account not credited & transaction ID not received",
    amountdeductedmultipletimes:"Amount Deducted multiple times",
    doublepaymentupdated:"Double payment updated",
    Erroneouslypaidinwrongaccount:"Erroneously paid in wrong account",
};
export const Defines_SupportTicketStatus = {"0":"Open","1":"Closed"};
