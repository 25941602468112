import {createSlice} from "@reduxjs/toolkit";
export const modalSlice = createSlice({
    name:"modal",
    initialState : {
        showloginmodal:false,
        showsignupmodal:false,
        showresetpasswordmodal:false,
        showlogoutmodal:false,
        showwallettopupmodal:false,
    },
    reducers : {
        UpdatemodalsDisplayStatus:(state,action)=>{
            state["showloginmodal"] = action.payload.showloginmodal===true?true:false;
            state["showsignupmodal"] = action.payload.showsignupmodal===true?true:false;
            state["showresetpasswordmodal"] = action.payload.showresetpasswordmodal===true?true:false;
            state["showlogoutmodal"] = action.payload.showlogoutmodal===true?true:false;
            state["showwallettopupmodal"] = action.payload.showwallettopupmodal===true?true:false;
            return state;
        }
    }
});
export const {UpdatemodalsDisplayStatus} = modalSlice.actions;
export default modalSlice.reducer;
