import {createSlice} from "@reduxjs/toolkit";

export const portalSlice = createSlice({
    name:"portal",
    initialState : {
        CompanyName:localStorage.getItem("CompanyName")!==null?localStorage.getItem("CompanyName"):"",
        CompanyCode:localStorage.getItem("CompanyCode")!==null?localStorage.getItem("CompanyCode"):"",
        CompanyAddress:localStorage.getItem("CompanyAddress")!==null?localStorage.getItem("CompanyAddress"):"",
        CompanyState:localStorage.getItem("CompanyState")!==null?localStorage.getItem("CompanyState"):"",
        CompanyCity:localStorage.getItem("CompanyCity")!==null?localStorage.getItem("CompanyCity"):"",
        CompanyPincode:localStorage.getItem("CompanyPincode")!==null?localStorage.getItem("CompanyPincode"):"",
        CompanyCountry:localStorage.getItem("CompanyCountry")!==null?localStorage.getItem("CompanyCountry"):"",
        CompanyMobile:localStorage.getItem("CompanyMobile")!==null?localStorage.getItem("CompanyMobile"):"",
        CompanyEmail:localStorage.getItem("CompanyEmail")!==null?localStorage.getItem("CompanyEmail"):"",
        CompanyWebsite:localStorage.getItem("CompanyWebsite")!==null?localStorage.getItem("CompanyWebsite"):"",
        CompanyWalletTypes:localStorage.getItem("CompanyWalletTypes")!==null?JSON.parse(localStorage.getItem("CompanyWalletTypes")):{},
        CompanyServiceTypes:localStorage.getItem("CompanyServiceTypes")!==null?JSON.parse(localStorage.getItem("CompanyServiceTypes")):{},
        CompanyShippingMethods:localStorage.getItem("CompanyShippingMethods")!==null?JSON.parse(localStorage.getItem("CompanyShippingMethods")):[],
        CompanyFacebookLink:localStorage.getItem("CompanyFacebookLink")!==null?localStorage.getItem("CompanyFacebookLink"):"",
        CompanyTwitterLink:localStorage.getItem("CompanyTwitterLink")!==null?localStorage.getItem("CompanyTwitterLink"):"",
        CompanyLinkedinLink:localStorage.getItem("CompanyLinkedinLink")!==null?localStorage.getItem("CompanyLinkedinLink"):"",
        CompanyInstagramLink:localStorage.getItem("CompanyInstagramLink")!==null?localStorage.getItem("CompanyInstagramLink"):"",
        //----
        BannerTypes:[],
        Banners:[],
    },
    reducers : {
        UpdateBannerTypes:(state,action)=>{
            state["BannerTypes"] = action.payload.BannerTypes;
            return state;
        },
        UpdateBanners:(state,action)=>{
            state["Banners"] = action.payload.Banners;
            return state;
        },
        UpdateCompanyDetails:(state,action)=>{
            state["CompanyName"] = action.payload.CompanyName;
            state["CompanyCode"] = action.payload.CompanyCode;
            state["CompanyAddress"] = action.payload.CompanyAddress;
            state["CompanyState"] = action.payload.CompanyState;
            state["CompanyCity"] = action.payload.CompanyCity;
            state["CompanyPincode"] = action.payload.CompanyPincode;
            state["CompanyCountry"] = action.payload.CompanyCountry;
            state["CompanyMobile"] = action.payload.CompanyMobile;
            state["CompanyEmail"] = action.payload.CompanyEmail;
            state["CompanyWebsite"] = action.payload.CompanyWebsite;
            state["CompanyServiceTypes"] = action.payload.CompanyServiceTypes;
            state["CompanyShippingMethods"] = action.payload.CompanyShippingMethods;
            state["CompanyFacebookLink"] = action.payload.CompanyFacebookLink;
            state["CompanyTwitterLink"] = action.payload.CompanyTwitterLink;
            state["CompanyLinkedinLink"] = action.payload.CompanyLinkedinLink;
            state["CompanyInstagramLink"] = action.payload.CompanyInstagramLink;
            return state;
        },
    }
});
export const { UpdateCompanyDetails, UpdateBannerTypes, UpdateBanners,} = portalSlice.actions;
export default portalSlice.reducer;
