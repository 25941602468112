import { Endpoint } from '../globals/defines';

const axios = require('axios').default;

export const GetBanners = async (ignorecache=false) => {
    try{
        const url = ignorecache !== true ? Endpoint+"banners":Endpoint+"banners?ignorecache="+new Date().getTime();
		const response = await axios.get(url);
		const json  = response.data;
		if(json.errorcode !== 0){
			return {status:false,message:json.errortext};
		}
		return {status:true,message:json.errortext,banners:json.banners};
	}catch(error){
		console.log("GetServiceRoutes Exception:",error);
		return {status:false,message:error.message};
	};
}

export const SaveBanner = async (BannerID,BannerName,BannerType,ButtonText,TextPlacement,StartDateTime,EndDateTime,RedirectLocation,BannerSortOrder,BannerImage,BannerTitle,BannerSubTitle,BannerImageId) => {
    if(BannerName === "") return {status:false,message:"Invalid Banner Name"};
    if(BannerType === "") return {status:false,message:"Invalid Banner Type"};
    if(BannerImage === "" && BannerID === "") return {status:false,message:"Invalid Image"};
    if(BannerSortOrder === "") BannerSortOrder = 0;
    try{
        const response = await axios.post(Endpoint+"banners",{BannerID,BannerName,BannerType,ButtonText,TextPlacement,StartDateTime,EndDateTime,RedirectLocation,BannerSortOrder,BannerImage,BannerTitle,BannerSubTitle,BannerImageId});
        const json = response.data;
        if(json.errorcode !== 0){
            return {status:false,message:json.errortext};
        }
        return {status:true,message:json.errortext,bannerid:json.bannerid,imageid:json.imageid};
    }
    catch(ex){
        return {status:false,message:ex.message};
    }
}