import {createSlice} from "@reduxjs/toolkit";
export const userSlice = createSlice({
    name:"user",
    initialState : {
        LoginStatus:localStorage.getItem("LoginStatus")=="true"?true:false,
        userid:localStorage.getItem("userid")!=null?localStorage.getItem("userid"):"",
        displayname:localStorage.getItem("displayname")!=null?localStorage.getItem("displayname"):"",
        email:localStorage.getItem("email")!=null?localStorage.getItem("email"):"",
        mobile:localStorage.getItem("mobile")!=null?localStorage.getItem("mobile"):"",
        emailverified:localStorage.getItem("emailverified")=="true"?true:false,
        mobileverified:localStorage.getItem("mobileverified")=="true"?true:false,
        kycverified:localStorage.getItem("kycverified")!=null?localStorage.getItem("kycverified"):false,
        roleid:localStorage.getItem("roleid")!=null?localStorage.getItem("roleid"):"",
        rolename:localStorage.getItem("rolename")!=null?localStorage.getItem("rolename"):"",
        privileges:localStorage.getItem("privileges")!=null?JSON.parse(localStorage.getItem("privileges")):{},
        admintools:localStorage.getItem("admintools")=="true"?true:false,
        wallets:{},
        totalbalance:0,
    },
    reducers : {
        UpdateLoginDetails:(state,action)=>{
            state["LoginStatus"] = action.payload.LoginStatus===true?true:false;
            state["userid"] = action.payload.userid===undefined?"":action.payload.userid;
			state["displayname"] = action.payload.displayname===undefined?"":action.payload.displayname;
			state["email"] = action.payload.email===undefined?"":action.payload.email;
			state["mobile"] = action.payload.mobile===undefined?"":action.payload.mobile;
			state["emailverified"] = action.payload.emailverified===true?true:false;
			state["mobileverified"] = action.payload.mobileverified===true?true:false;
			state["kycverified"] = action.payload.kycverified===true?true:false;
			state["roleid"] = action.payload.roleid===undefined?"":action.payload.roleid;
			state["rolename"] = action.payload.rolename===undefined?"":action.payload.rolename;
			state["privileges"] = action.payload.privileges===undefined?{}:action.payload.privileges;
			state["admintools"] = action.payload.admintools===true?true:false;
            state["referralid"] = action.payload.referralid===undefined?"":action.payload.referralid;
            return state;
        },
        UpdateWallets:(state,action)=>{
            state["wallets"] = action.payload.wallets;
            state["totalbalance"] = action.payload.totalbalance;
            return state;
        },
    }
});
export const {UpdateLoginDetails,UpdateWallets} = userSlice.actions;
export default userSlice.reducer;
